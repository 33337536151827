<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>账号余额</span>
    </div>
    <el-row>
      <el-button type="text" class="font-info" icon="el-icon-user">会员等级:</el-button> <i class="level"> {{userLevelId}}</i>
    </el-row>
    <el-row>
      <el-button type="text" class="font-info" icon="el-icon-coin">账号余额:</el-button>  <i class=" balance"> {{balance}} </i>元
    </el-row>
  </el-card>
</template>

<script>
import {getSessionUser} from '../../api/auth'
export default {
  mounted() {
    this.isLoggedIn = (this.$store.state.user.token !== null || this.$store.state.user.token !== "")
    && (this.$store.state.user.loginNameType!=null )? true : false;
    this.getUserList();
  },
  data() {
    return {
      visible: false,
      balance:0,
      userLevelId:'无',
    };
  },
  methods: {
    getUserList(){
      getSessionUser({}).then((result) => {
        if(result!=null){
          this.$store.dispatch("setUserInfo", result);
          this.balance=result.blance;
         if(result.userLevelId==null){
           this.userLevelId='无';
         }else {
           //TODO 做判断，赋值
           this.userLevelId=result.userLevelId;
         }
        }
      }).catch((error) => {
        console.log(error);
      })
    },
    linkOverseas(row) {
      window.open(row.enUrl);
    },
    linkDomestic(row) {
      window.open(row.zhUrl);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .font-info {
      color: #3e3e3e;
    }
  .font-warning{
    color: #E6A23C;
  }
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .level{
     font-size: 12px;
     color: #E6A23C;
     margin-top: 6px;
     font-size: 24px;
   }
  .balance{
    font-size: 12px;
    color: #409EFF;
    margin-top: 6px;
    font-size: 24px;
  }

</style>
